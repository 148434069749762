import { useState } from "react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const ColorPicker = ({ selectedColor, setSelectedColor, colors, buttons }) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const toggleDropdown = () => setDropdownOpen(!dropdownOpen);

  const handleCloseDropdown = (event) => {
    if (!event.currentTarget.contains(event.relatedTarget)) {
      setDropdownOpen(false);
    }
  }

  const displayedColors = colors.slice(0, buttons);
  const dropdownColors = colors.slice(buttons);

  return (
    <div className="flex gap-4 items-start relative">
      {displayedColors.map((color, index) => (
        <div key={color}>
          <button
            onClick={() => setSelectedColor(color)}
            className={`w-16 h-16 border border-gray-300 rounded ${
              selectedColor === color ? "ring-2 ring-offset-2 ring-gray-500" : ""
            }`}
            style={{ backgroundColor: color }}
          />
        </div>
      ))}
      {dropdownColors.length > 0 && (
        <div className="relative" onBlur={(event) => handleCloseDropdown(event)}>
          <button
            className="w-16 h-16 border border-gray-300 rounded bg-gray-100 hover:bg-gray-200 flex items-center justify-center"
            onClick={toggleDropdown}>
            <ExpandMoreIcon className="text-gray-700" />
          </button>
          {dropdownOpen && (
            <div className="absolute top-full right-0 w-64 mt-2 bg-white border border-gray-300 rounded shadow-lg z-10 grid grid-cols-3 gap-2 p-2">
              {dropdownColors.map((color, index) => (
                <button
                  key={color}
                  onClick={() => setSelectedColor(color)}
                  className={`w-16 h-16 border border-gray-300 rounded ${
                    selectedColor === color ? "ring-2 ring-offset-2 ring-gray-500" : ""
                  }`}
                  style={{ backgroundColor: color }}
                />
              ))}
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default ColorPicker;
