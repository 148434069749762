import "./App.css";
import React, {useCallback, useEffect, useState} from "react";
import axios from "axios";
import Stack from "@mui/material/Stack";
import Slider from "@mui/material/Slider";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import StickerPicker from "./components/StickerPicker";
import ColorPicker from "./components/ColorPicker";
import ClipLoader from "react-spinners/ClipLoader";
import domtoimage from "dom-to-image-more";
import {FontPicker} from "./components/FontPicker";
import {TextInput} from "./components/TextInput";

const stickerReactWp = document.getElementById("sticker-react-wp");

function App() {
    const [loading, setLoading] = useState(true);
    const [fontColor, setFontColor] = useState(null);
    const [bgColor, setBgColor] = useState(null);
    const [texts, setTexts] = useState(["", "", "", ""]);
    const [selectedFont, setSelectedFont] = useState("Arial");
    const [selectedSticker, setSelectedSticker] = useState(null);
    const [selectedPattern, setSelectedPattern] = useState(null);
    const [height, setHeight] = useState(null);
    const [width, setWidth] = useState(null);
    const [isCircle, setIsCircle] = useState(false);
    const [stickers, setStickers] = useState([]);
    const [ratio, setRatio] = useState(100);
    const [availableFonts, setAvailableFonts] = useState([]);
    const [is_33196, setIs_33196] = useState(null);
    const [fontColors, setFontColors] = useState([
        "#000000",
        "#FFFFFF",
        "#8C8C8C",
        "#D9D9D9",
        "#163B78",
        "#31BBD8",
        "#7CD8FF",
        "#CAEFFF",
        "#D7F7F6",
        "#32743A",
        "#85C006",
        "#C5D8A5",
        "#D4E6D8",
        "#FF0000",
        "#FF008A",
        "#FF93CD",
        "#E3A3E0",
        "#FEC7DC",
        "#FFEAF1",
        "#F1CB06",
        "#FFE900",
        "#F6F382",
        "#A66642",
        "#AB8266",
        "#E1D6C4",
        "#F9DFAF",
    ]);

    const [bgColors, setBgColors] = useState([
        "#000000",
        "#FFFFFF",
        "#8C8C8C",
        "#D9D9D9",
        "#163B78",
        "#31BBD8",
        "#7CD8FF",
        "#CAEFFF",
        "#D7F7F6",
        "#32743A",
        "#85C006",
        "#C5D8A5",
        "#D4E6D8",
        "#FF0000",
        "#FF008A",
        "#FF93CD",
        "#E3A3E0",
        "#FEC7DC",
        "#FFEAF1",
        "#F1CB06",
        "#FFE900",
        "#F6F382",
        "#A66642",
        "#AB8266",
        "#E1D6C4",
        "#F9DFAF",
    ]);

    const [patterns, setPatterns] = useState([]);

    useEffect(() => {
        getData();
    }, []);

    useEffect(() => {
        setRatio(100);
    }, [texts]);

    const handleInputChange = useCallback(
        (index) => (e) => {
            const newText = e.target.value.slice(0, 34);
            const newTexts = [...texts];
            newTexts[index] = newText;
            setTexts(newTexts);
        },
        [texts]
    );

    const baseUrl = stickerReactWp && stickerReactWp.getAttribute("data-baseUrl");
    const hasDataProductAttribute = stickerReactWp && stickerReactWp.getAttribute("data-product");
    if (!hasDataProductAttribute || !baseUrl) {
        console.error("Sticker React WP element or data-product attribute not found");
        return null;
    }

    const cartForm = document.querySelector(".cart");

    const firstRowText = () => {
        const textAvailable = texts[0] || texts[1];
        if (textAvailable) {
            return texts[0];
        }
        return "Max Mustermann";
    };

    const secondRowText = () => {
        const textAvailable = texts[0] || texts[1];
        if (textAvailable) {
            return texts[1];
        }
        return "0664 12 34 567";
    };

    const base = {
        color: fontColor ?? fontColors[0],
        backgroundColor: bgColor ?? bgColors[0],
        backgroundImage: selectedPattern ? `url(${selectedPattern})` : null,
        backgroundBlendMode: "multiply",
        backgroundSize: "contain",
    }

    const stickerContainerStyle = {
        maxHeight: "100%",
        maxWidth: "30%",
        padding: "10px",
        marginLeft: "5px",
    };

    const sKlebeetikettenIconStyle = {
        maxHeight: "100%",
        maxWidth: "20%",
        padding: "2px 4px",
        marginLeft: "5px",
    };
    const buegeletikettenIconStyle = {
        maxWidth: "30%",
        maxHeight: "90%",
        padding: "2px 2px 2px 10px",
    };
    const stickerContainerStyle_2 = {
        maxWidth: "50%",
        maxHeight: "55%",
        padding: "0px 5px",
    };

    const dynamicStickerStyle = {
        ...base, ...{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            width: `${width}mm`,
            height: `${height}mm`,
            position: "relative",
            borderRadius: "8px",
        }
    };
    const roundstickerStyle = {
        ...base, ...{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            width: `${width}mm`,
            height: `${height}mm`,
            position: "relative",
            borderRadius: "50%",
        }
    };

    const stickerStyle = {
        ...base, ...{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            width: `75mm`,
            height: `19mm`,
            position: "relative",
            borderRadius: "8px",
        }
    };
    const stickerStyle_4 = {
        ...base, ...{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            width: `45mm`,
            height: `6mm`,
            position: "relative",
            borderRadius: "8px",
        }
    };
    const stickerStyle_1 = {
        ...base, ...{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            width: "50mm",
            height: "19mm",
            position: "relative",
            borderRadius: "8px",
        }
    };
    const stickerStyle_2 = {
        ...base, ...{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            width: "40mm",
            height: "35mm",
            position: "relative",
            borderRadius: "8px",
            borderBottomLeftRadius: "50%",
            borderBottomRightRadius: "50%",
        }
    };

    const stickerStyle_3 = {
        ...base, ...{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            width: "30mm",
            height: "4mm",
            position: "relative",
            borderRadius: "8px",
        }
    };
    const dynamicTextContainerStyle = {
        overflow: "hidden",
        maxHeight: "50%",
        minHeight: "50%",
        flex: "1 1 0%",
        textAlign: "center",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        fontSize: calculateFontSize(15),
        zIndex: "2",
        color: fontColor,
        fontFamily: selectedFont,
        alignItems: "center",
        paddingTop: "25px",
    };

    const klebeetikettenTextContainerStyle = {
        overflow: "hidden",
        maxHeight: "90%",
        maxWidth: "70%",
        minHeight: "90%",
        minWidth: "70%",
        flex: "1 1 0%",
        textAlign: "center",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        fontSize: calculateFontSize(16),
        zIndex: "2",
        color: fontColor,
        fontFamily: selectedFont,
        alignItems: "center",
    };
    const sKlebeetikettenTextContainerStyle = {
        overflow: "hidden",
        maxHeight: "95%",
        maxWidth: "80%",
        minHeight: "95%",
        minWidth: "80%",
        flex: "1 1 0%",
        textAlign: "center",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        fontSize: calculateFontSize(14),
        zIndex: "2",
        color: fontColor,
        fontFamily: selectedFont,
        alignItems: "center",
    };

    const buegeletikettenTextContainerStyle = {
        overflow: "hidden",
        maxHeight: "90%",
        maxWidth: "70%",
        minHeight: "90%",
        minWidth: "70%",
        flex: "1 1 0%",
        textAlign: "center",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        fontSize: calculateFontSize(13),
        zIndex: "2",
        color: fontColor,
        fontFamily: selectedFont,
        alignItems: "center",
    };
    const schuhaufkleberTextContainerStyle = {
        paddingTop: "10px",
        overflow: "hidden",
        maxHeight: "45%",
        maxWidth: "90%",
        minHeight: "45%",
        minWidth: "90%",
        flex: "1 1 0%",
        textAlign: "center",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        fontSize: calculateFontSize(14),
        zIndex: "2",
        color: fontColor,
        fontFamily: selectedFont,
        alignItems: "center",
    };

    function calculateFontSize(number) {
        return Math.round((number * ratio) / 100) + "px";
    }

    const xsKlebeetikettenTextContainerStyle = {
        overflow: "hidden",
        maxHeight: "95%",
        maxWidth: "100%",
        minHeight: "95%",
        minWidth: "100%",
        flex: "1 1 0%",
        textAlign: "center",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        fontSize: calculateFontSize(10),
        zIndex: "2",
        color: fontColor,
        fontFamily: selectedFont,
        alignItems: "center",
    };
    const dynamicTextStyle = {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        paddingTop: "2px",
        paddingBottom: "2px",
        flex: "0 0 0%",
        width: "fit-content",
        whiteSpace: "nowrap",
        lineHeight: "1",
    };
    const textStyle = {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        paddingLeft: "2px",
        paddingRight: "2px",
        flex: "0 0 0%",
        width: "fit-content",
        whiteSpace: "nowrap",
        lineHeight: "20px",
    };

    const xsKlebeetikettenTextStyle = {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        paddingLeft: "2px",
        paddingTop: "5px",
        paddingBottom: "5px",
        paddingRight: "2px",
        flex: "0 0 0%",
        width: "fit-content",
        whiteSpace: "nowrap",
    };

    const roundstickerContainerStyle = {
        maxWidth: "50%",
        overflow: "auto",
        marginBottom: "8px",
    };

    const handleStickerChange = (selectedOption) => {
        setSelectedSticker(selectedOption);
    };

    const handlePatternChange = (selectedOption) => {
        setSelectedPattern(selectedOption);
    };

    const setBase64InForm = (base64String) => {
        const hiddenInputProduct = cartForm?.querySelector('input[name="fpd_product"]');
        hiddenInputProduct.value = JSON.stringify([
            {
                elements: [],
                fontColor: fontColor,
                bgColor: bgColor,
                selectedSticker: selectedSticker,
                selectedFont: selectedFont,
                texts: texts,
                ratio: ratio,
            },
        ]);
        const hiddenInputThumbnail = cartForm?.querySelector('input[name="fpd_product_thumbnail"]');
        if (hiddenInputThumbnail) {
            hiddenInputThumbnail.value = base64String;
        }
    };

    const generateBase64 = async () => {
        console.log("gen base64 starting");
        const stickerDiv = document.getElementById("custom-sticker-react");
        const width = stickerDiv.clientWidth;
        const height = stickerDiv.clientHeight;
        return await domtoimage.toPng(stickerDiv, {width, height});
    };

    const getData = async () => {
        setLoading(true);
        setPatterns([
            {link: null},
            {link: `${baseUrl}/wp-content/plugins/sticker-design-react/build/patterns/sterne.svg`},
            {link: `${baseUrl}/wp-content/plugins/sticker-design-react/build/patterns/regenbogen.svg`},
            {link: `${baseUrl}/wp-content/plugins/sticker-design-react/build/patterns/kreise.svg`}
        ]);
        try {
            const response = await axios.get(`${baseUrl}/wp-json/wp/v2/product-design-stick?per_page=100`);
            const stickersArray = response.data.map((sticker) => ({
                link: sticker.featured_image_url,
                category: "all",
            }));
            setStickers(stickersArray);
            const response2 = await axios.get(`${baseUrl}/wp-json/wp/v2/font-family`);
            const fontsArray = [];
            response2.data.forEach((font, index) => {
                if (index === 0) {
                    setSelectedFont(font.title.rendered);
                }
                fontsArray.push({
                    title: font.title.rendered,
                    url: font.font_url,
                });
            });
            setAvailableFonts(fontsArray);
        } catch (error) {
            console.error("Error fetching data from the API:", error);
        }
        try {
            if (stickerReactWp) {
                const id = stickerReactWp.getAttribute("data-product");
                const numericId = parseInt(id);
                if (!isNaN(numericId) && numericId === 33196) {
                    setIs_33196(true);
                } else {
                    setIs_33196(false);
                }
                const response = await axios.get(`${baseUrl}/wp-json/wp/v2/product-designer/${id}`);
                if (response.data.is_circle === "Yes") {
                    setIsCircle(true);
                }
                setHeight(response.data.design_height);
                setWidth(response.data.design_width);
                setBgColor(response.data.default_background);
                setFontColor(response.data.default_color);
                setSelectedSticker(response.data.custom_image_url);

                const newBgColor = response.data.default_background;
                if (!bgColors.includes(newBgColor)) {
                    setBgColors((prevBgColors) => {
                        const newColors = new Set();
                        [...prevBgColors, newBgColor].forEach((color) => {
                            newColors.add(color);
                        });
                        return Array.from(newColors.values());
                    });
                }

                const newFontColor = response.data.default_color;
                if (!fontColors.includes(newFontColor)) {
                    setFontColors((prevFontColors) => {
                        const newColors = new Set();
                        [...prevFontColors, newFontColor].forEach((color) => {
                            newColors.add(color);
                        });
                        return Array.from(newColors.values());
                    });
                }
                const newSticker = {
                    link: response.data.custom_image_url,
                    category: "all",
                };

                if (!stickers.some((sticker) => sticker.link === newSticker.link)) {
                    setStickers((prevStickers) => {
                        if (prevStickers.findIndex((sticker) => sticker.link === newSticker.link) === -1) {
                            return [...prevStickers, newSticker];
                        } else {
                            return prevStickers;
                        }
                    });
                }

                const cartButtons = document.querySelectorAll(".single_add_to_cart_button");
                cartButtons.forEach((button) => {
                    button.onclick = async (event) => {
                        button.innerHTML = "Bitte warten...";
                        console.log("imageGenerated", window.imageGenerated);
                        if (!window.imageGenerated) {
                            window.imageGenerated = true;
                            event.preventDefault();
                            event.stopPropagation();
                            await handleExternalClick();
                            button.click();
                        }
                    };
                });
            }
        } catch (error) {
            console.error("Error fetching data from the API:", error);
        }
        setLoading(false);
    };

    const downloadImage = async () => {
        console.log("generating base64");
        const base64 = await generateBase64();
        console.log("generated base64");
        const a = document.createElement("a"); //Create <a>
        a.href = base64; //Image Base64 Goes here
        a.download = "Image.png"; //File name Here
        a.click(); //Downloaded file
        console.log("base64 set in form");
    };

    const handleExternalClick = async () => {
        console.log("generating base64");
        const base64 = await generateBase64();
        console.log("generated base64");
        setBase64InForm(base64);
        console.log("base64 set in form");
    };

    return (
        <>
            <style>
                {`
                input.p-3 {padding: 0.75rem !important;}
          
                #custom-sticker-react, #custom-sticker-react * {
                    border: 0;
                }
                
                /* latin-ext */
                @font-face {
                    font-family: 'Madimi One';
                    font-style: normal;
                    font-weight: 400;
                    font-display: swap;
                    src: url(https://www.mein-kinderzimmer.at/wp-content/plugins/sticker-design-react/build/fonts/madimione/2V0YKIEADpA8U6RygDnZVF0iB6PPZ2TR8w.woff2) format('woff2');
                    unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
                }
                
                /* latin */
                @font-face {
                    font-family: 'Madimi One';
                    font-style: normal;
                    font-weight: 400;
                    font-display: swap;
                    src: url(https://www.mein-kinderzimmer.at/wp-content/plugins/sticker-design-react/build/fonts/madimione/2V0YKIEADpA8U6RygDnZVFMiB6PPZ2Q.woff2) format('woff2');
                    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
                }
                
               /* latin-ext */
                @font-face {
                  font-family: 'Truculenta';
                  font-style: normal;
                  font-weight: 700;
                  font-stretch: 100%;
                  font-display: swap;
                  src: url(https://www.mein-kinderzimmer.at/wp-content/plugins/sticker-design-react/build/fonts/truculenta/LhWKMVvBKusVIfNYGi1-WvRV2jkJOemySNrBce32MaGn36y6Oa218KPtiW2p9-B8y8ao.woff2) format('woff2');
                  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
                }
                /* latin */
                @font-face {
                  font-family: 'Truculenta';
                  font-style: normal;
                  font-weight: 700;
                  font-stretch: 100%;
                  font-display: swap;
                  src: url(https://www.mein-kinderzimmer.at/wp-content/plugins/sticker-design-react/build/fonts/truculenta/LhWKMVvBKusVIfNYGi1-WvRV2jkJOemySNrBce32MaGn36y6Oa218KPth22p9-B8yw.woff2) format('woff2');
                  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
                }
                
                /* latin-ext */
                @font-face {
                  font-family: 'Dosis';
                  font-style: normal;
                  font-weight: 700;
                  font-display: swap;
                  src: url(https://www.mein-kinderzimmer.at/wp-content/plugins/sticker-design-react/build/fonts/dosis/HhyJU5sn9vOmLxNkIwRSjTVNWLEJ0LQl1RMEbKsUPqjmG8Q.woff2) format('woff2');
                  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
                }
                /* latin */
                @font-face {
                  font-family: 'Dosis';
                  font-style: normal;
                  font-weight: 700;
                  font-display: swap;
                  src: url(https://www.mein-kinderzimmer.at/wp-content/plugins/sticker-design-react/build/fonts/dosis/HhyJU5sn9vOmLxNkIwRSjTVNWLEJ0LQl2xMEbKsUPqjm.woff2) format('woff2');
                  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
                }
                
                /* latin-ext */
                @font-face {
                  font-family: 'Dancing Script';
                  font-style: normal;
                  font-weight: 700;
                  font-display: swap;
                  src: url(https://www.mein-kinderzimmer.at/wp-content/plugins/sticker-design-react/build/fonts/dancingscript/If2cXTr6YS-zF4S-kcSWSVi_sxjsohD9F50Ruu7B1i03ROp8hNX6plRPjLo.woff2) format('woff2');
                  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
                }
                /* latin */
                @font-face {
                  font-family: 'Dancing Script';
                  font-style: normal;
                  font-weight: 700;
                  font-display: swap;
                  src: url(https://www.mein-kinderzimmer.at/wp-content/plugins/sticker-design-react/build/fonts/dancingscript/If2cXTr6YS-zF4S-kcSWSVi_sxjsohD9F50Ruu7B1i03Sup8hNX6plRP.woff2) format('woff2');
                  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
                }
                
                /* latin-ext */
                @font-face {
                  font-family: 'Anton';
                  font-style: normal;
                  font-weight: 400;
                  font-display: swap;
                  src: url(https://www.mein-kinderzimmer.at/wp-content/plugins/sticker-design-react/build/fonts/anton/1Ptgg87LROyAm3K9-C8CSKlvPfE.woff2) format('woff2');
                  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
                }
                /* latin */
                @font-face {
                  font-family: 'Anton';
                  font-style: normal;
                  font-weight: 400;
                  font-display: swap;
                  src: url(https://www.mein-kinderzimmer.at/wp-content/plugins/sticker-design-react/build/fonts/anton/1Ptgg87LROyAm3Kz-C8CSKlv.woff2) format('woff2');
                  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
                }
               
                /* latin-ext */
                @font-face {
                  font-family: 'Slackside One';
                  font-style: normal;
                  font-weight: 400;
                  font-display: swap;
                  src: url(https://www.mein-kinderzimmer.at/wp-content/plugins/sticker-design-react/build/fonts/slacksideone/EJRQQgMrXdcGsiBuvnRxodTwZyffoOFC-I2irw.woff2) format('woff2');
                  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
                }
                /* latin */
                @font-face {
                  font-family: 'Slackside One';
                  font-style: normal;
                  font-weight: 400;
                  font-display: swap;
                  src: url(https://www.mein-kinderzimmer.at/wp-content/plugins/sticker-design-react/build/fonts/slacksideone/EJRQQgMrXdcGsiBuvnRxodTwZynfoOFC-I0.woff2) format('woff2');
                  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
                }
                
                /* latin-ext */
                @font-face {
                  font-family: 'Merriweather';
                  font-style: normal;
                  font-weight: 400;
                  font-display: swap;
                  src: url(https://www.mein-kinderzimmer.at/wp-content/plugins/sticker-design-react/build/fonts/merriweather/u-440qyriQwlOrhSvowK_l5-ciZMdeX3rsHo.woff2) format('woff2');
                  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
                }
                /* latin */
                @font-face {
                  font-family: 'Merriweather';
                  font-style: normal;
                  font-weight: 400;
                  font-display: swap;
                  src: url(https://www.mein-kinderzimmer.at/wp-content/plugins/sticker-design-react/build/fonts/merriweather/u-440qyriQwlOrhSvowK_l5-fCZMdeX3rg.woff2) format('woff2');
                  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
                }
                
                /* latin-ext */
                @font-face {
                  font-family: 'Roboto';
                  font-style: normal;
                  font-weight: 400;
                  font-display: swap;
                  src: url(https://www.mein-kinderzimmer.at/wp-content/plugins/sticker-design-react/build/fonts/roboto/KFOmCnqEu92Fr1Mu7GxKKTU1Kvnz.woff2) format('woff2');
                  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
                }
                /* latin */
                @font-face {
                  font-family: 'Roboto';
                  font-style: normal;
                  font-weight: 400;
                  font-display: swap;
                  src: url(https://www.mein-kinderzimmer.at/wp-content/plugins/sticker-design-react/build/fonts/roboto/KFOmCnqEu92Fr1Mu4mxKKTU1Kg.woff2) format('woff2');
                  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
                }
                `}
            </style>
            <div className="relative my-10 w-full max-w-2xl mx-auto">
                <div className="flex items-center">
                    <hr className="border-t border-gray-400 flex-grow"/>
                    <p className="mx-4 text-gray-500 font-bold text-2xl mb-4">
                        Gestalten Sie Ihre Etiketten mit ein paar Klicks!
                    </p>
                    <hr className="border-t border-gray-400 flex-grow"/>
                </div>
            </div>

            <ClipLoader
                color="#ffae99"
                loading={loading}
                cssOverride={{
                    display: "block",
                    margin: "0 auto",
                    borderColor: "#ffae99",
                }}
                size={150}
                aria-label="Loading Spinner"
                data-testid="loader"
            />
            {!loading && (
                <div
                    className="max-w-full p-4 flex flex-col gap-4 items-center justify-center xl:p-5 xl:flex-row xl:gap-10 xl:items-start">
                    <div style={{maxWidth: '34rem'}}>
                        <div className="flex flex-col w-full gap-4">
                            {texts.slice(0, 2).map((text, index) => {
                                const id = `text_${index}`;
                                return (
                                    <TextInput
                                        key={id}
                                        id={id}
                                        label={`Textzeile ${index + 1}`}
                                        value={text}
                                        onChange={(e) => handleInputChange(index)(e)}
                                        onPaste={(e) => e.preventDefault()}
                                    />
                                );
                            })}
                        </div>

                        <div className="grid grid-cols-1 gap-4">
                            <div className="flex flex-col">
                                <p className="mb-2 font-light">Schriftart wählen</p>
                                <FontPicker
                                    selectedFont={selectedFont}
                                    setSelectedFont={setSelectedFont}
                                    fonts={availableFonts}
                                    buttons={6}
                                />
                            </div>

                            <div className="flex flex-col">
                                <p className="mb-2 font-light">Symbol wählen</p>
                                <StickerPicker
                                    selectedSticker={selectedSticker}
                                    setSelectedSticker={handleStickerChange}
                                    stickers={stickers}
                                    buttons={6}
                                />
                            </div>

                            <div className="flex flex-col">
                                <p className="mb-2 font-light">Muster wählen</p>
                                <StickerPicker
                                    selectedSticker={selectedPattern}
                                    setSelectedSticker={handlePatternChange}
                                    stickers={patterns}
                                    buttons={6}
                                />
                            </div>

                            <div className="flex flex-col">
                                <p className="mb-2 font-light">Hintergrundfarbe wählen</p>
                                <ColorPicker
                                    selectedColor={bgColor}
                                    setSelectedColor={setBgColor}
                                    colors={bgColors}
                                    buttons={6}
                                />
                            </div>

                            <div className="flex flex-col">
                                <p className="mb-2 font-light">Textfarbe wählen</p>
                                <ColorPicker
                                    selectedColor={fontColor}
                                    setSelectedColor={setFontColor}
                                    colors={fontColors}
                                    buttons={6}
                                />
                            </div>

                            <div className="flex flex-col">
                                <p className="mb-2 font-light">Textgröße wählen</p>
                                <Stack
                                    spacing={2}
                                    direction="row"
                                    alignItems="center">
                                    <RemoveIcon
                                        style={{cursor: "pointer"}}
                                        onClick={() => setRatio(ratio - 1)}
                                    />
                                    <Slider
                                        aria-label="Text size"
                                        value={ratio ?? 100}
                                        onChange={(e) => setRatio(e.target.value)}
                                        valueLabelDisplay="auto"
                                        min={50}
                                        max={150}
                                    />
                                    <AddIcon
                                        style={{cursor: "pointer"}}
                                        onClick={() => setRatio(ratio + 1)}
                                    />
                                </Stack>
                            </div>
                        </div>

                        <div className="py-10 px-10">
                            <p className="font-semibold text-lg">Info:</p>
                            <p className="mt-3 mb-5 text-gray-700">
                                Wir prüfen alle Designs vor dem Druck. Falls im Konfigurator die Schrift oder das Symbol
                                abgeschnitten
                                oder zu klein dargestellt wird, prüfen wir es und passen es optimal für dich an.
                            </p>
                        </div>
                    </div>
                    {is_33196 ? (
                        <div
                            id="custom-sticker-react"
                            className="grid grid-cols-2 place-items-center py-4 px-4"
                            style={{
                                backgroundColor: "#D9D9D9",
                                borderRadius: "20px",
                            }}>
                            <div>
                                <div
                                    className="my-4"
                                    style={stickerStyle_3}>
                                    <div
                                        id="text-container_3"
                                        style={xsKlebeetikettenTextContainerStyle}>
                                        <div
                                            id="text-span_3"
                                            style={xsKlebeetikettenTextStyle}>
                                            {firstRowText()}
                                        </div>
                                    </div>
                                </div>
                                <p className="text-xl text-center">Etiketten "XS"</p>
                            </div>
                            <div>
                                <div
                                    className="my-4"
                                    style={stickerStyle_4}>
                                    <img
                                        style={sKlebeetikettenIconStyle}
                                        src={selectedSticker || stickers[0].link}
                                    />
                                    <div
                                        id="text-container"
                                        style={sKlebeetikettenTextContainerStyle}>
                                        <div
                                            id="text-span_4"
                                            style={textStyle}>
                                            {firstRowText()}
                                        </div>
                                    </div>
                                </div>
                                <p className="text-xl text-center">Etiketten "S"</p>
                            </div>
                            <div className="col-span-2">
                                <div
                                    className="my-4"
                                    style={stickerStyle}>
                                    <img
                                        style={stickerContainerStyle}
                                        src={selectedSticker || stickers[0].link}
                                    />
                                    <div
                                        id="text-container"
                                        style={klebeetikettenTextContainerStyle}>
                                        <div
                                            className="first-row"
                                            style={textStyle}>
                                            {firstRowText()}
                                        </div>
                                        <div
                                            className="second-row"
                                            style={textStyle}>
                                            {secondRowText()}
                                        </div>
                                    </div>
                                </div>
                                <p className="text-xl text-center">Etiketten "M"</p>
                            </div>
                            <div className="col-span-2">
                                <div
                                    className="my-4"
                                    style={stickerStyle_1}>
                                    <img
                                        src={selectedSticker || stickers[0].link}
                                        style={buegeletikettenIconStyle}
                                    />
                                    <div
                                        id="text-container_1"
                                        style={buegeletikettenTextContainerStyle}>
                                        <div
                                            className="first-row"
                                            style={textStyle}>
                                            {firstRowText()}
                                        </div>
                                        <div
                                            className="second-row"
                                            style={textStyle}>
                                            {secondRowText()}
                                        </div>
                                    </div>
                                </div>
                                <p className="text-xl text-center">Bügeletiketten</p>
                            </div>
                            <div className="col-span-2">
                                <div
                                    className="my-4"
                                    style={stickerStyle_2}>
                                    <div
                                        id="text-container_2_1"
                                        style={schuhaufkleberTextContainerStyle}>
                                        <div
                                            className="first-row"
                                            style={textStyle}>
                                            {firstRowText()}
                                        </div>
                                        <div
                                            className="second-row"
                                            style={textStyle}>
                                            {secondRowText()}
                                        </div>
                                    </div>
                                    <img
                                        src={selectedSticker || stickers[0].link}
                                        style={stickerContainerStyle_2}
                                    />
                                </div>
                                <p className="text-xl text-center">Schuhaufkleber</p>
                            </div>
                        </div>
                    ) : isCircle ? (
                        <div
                            id="custom-sticker-react"
                            className="md:py-10 md:px-20 py-2 px-2 md:ml-20"
                            style={{
                                backgroundColor: "#D9D9D9",
                                borderRadius: "30px",
                            }}>
                            <div className="flex flex-col md:flex-row md:items-center justify-start">
                                <div className="flex md:mx-20">
                                    <div className="flex flex-col items-center">
                                        <div className="flex">
                                            <div className="flex items-center">
                                                <p className="md:text-xl">{height}mm</p>
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    width="32"
                                                    height="70"
                                                    viewBox="0 0 16 127"
                                                    fill="none">
                                                    <path
                                                        d="M8.70711 0.292893C8.31658 -0.0976311 7.68342 -0.0976311 7.29289 0.292893L0.928932 6.65685C0.538408 7.04738 0.538408 7.68054 0.928932 8.07107C1.31946 8.46159 1.95262 8.46159 2.34315 8.07107L8 2.41421L13.6569 8.07107C14.0474 8.46159 14.6805 8.46159 15.0711 8.07107C15.4616 7.68054 15.4616 7.04738 15.0711 6.65685L8.70711 0.292893ZM7.2929 126.707C7.68342 127.098 8.31659 127.098 8.70711 126.707L15.0711 120.343C15.4616 119.953 15.4616 119.319 15.0711 118.929C14.6805 118.538 14.0474 118.538 13.6569 118.929L8.00001 124.586L2.34315 118.929C1.95263 118.538 1.31946 118.538 0.928937 118.929C0.538413 119.319 0.538413 119.953 0.928937 120.343L7.2929 126.707ZM7 1L7.00001 126L9.00001 126L9 1L7 1Z"
                                                        fill="black"
                                                    />
                                                </svg>
                                            </div>
                                            <div
                                                className="md:mx-10 my-4"
                                                style={roundstickerStyle}>
                                                <div
                                                    id="text-container-single"
                                                    style={dynamicTextContainerStyle}>
                                                    <div
                                                        className="first-row"
                                                        style={dynamicTextStyle}>
                                                        {firstRowText()}
                                                    </div>
                                                    <div
                                                        className="second-row"
                                                        style={dynamicTextStyle}>
                                                        {secondRowText()}
                                                    </div>
                                                </div>
                                                <img
                                                    src={selectedSticker || stickers[0].link}
                                                    style={roundstickerContainerStyle}
                                                />
                                            </div>
                                        </div>
                                        <div
                                            className="text-center"
                                            style={{marginLeft: "25%"}}>
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="130"
                                                height="16"
                                                viewBox="0 0 302 16"
                                                fill="none">
                                                <path
                                                    d="M0.292893 7.29289C-0.0976311 7.68342 -0.0976311 8.31658 0.292893 8.70711L6.65685 15.0711C7.04738 15.4616 7.68054 15.4616 8.07107 15.0711C8.46159 14.6805 8.46159 14.0474 8.07107 13.6569L2.41421 8L8.07107 2.34315C8.46159 1.95262 8.46159 1.31946 8.07107 0.928932C7.68054 0.538408 7.04738 0.538408 6.65685 0.928932L0.292893 7.29289ZM301.707 8.70711C302.098 8.31658 302.098 7.68342 301.707 7.29289L295.343 0.928932C294.953 0.538408 294.319 0.538408 293.929 0.928932C293.538 1.31946 293.538 1.95262 293.929 2.34315L299.586 8L293.929 13.6569C293.538 14.0474 293.538 14.6805 293.929 15.0711C294.319 15.4616 294.953 15.4616 295.343 15.0711L301.707 8.70711ZM1 9H301V7H1V9Z"
                                                    fill="black"
                                                />
                                            </svg>
                                            <p className="md:text-xl">{width}mm</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ) : (
                        <div
                            id="custom-sticker-react"
                            className="md:py-10 md:px-20 py-2 px-2 md:mx-20"
                            style={{
                                backgroundColor: "#D9D9D9",
                                borderRadius: "30px",
                            }}>
                            <div className="flex flex-col md:flex-row md:items-center justify-start">
                                <div className="flex md:mx-20">
                                    <div className="flex flex-col items-center">
                                        <div className="flex">
                                            <div className="flex items-center">
                                                <p className="md:text-xl">{height}mm</p>
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    width="32"
                                                    height="70"
                                                    viewBox="0 0 16 127"
                                                    fill="none">
                                                    <path
                                                        d="M8.70711 0.292893C8.31658 -0.0976311 7.68342 -0.0976311 7.29289 0.292893L0.928932 6.65685C0.538408 7.04738 0.538408 7.68054 0.928932 8.07107C1.31946 8.46159 1.95262 8.46159 2.34315 8.07107L8 2.41421L13.6569 8.07107C14.0474 8.46159 14.6805 8.46159 15.0711 8.07107C15.4616 7.68054 15.4616 7.04738 15.0711 6.65685L8.70711 0.292893ZM7.2929 126.707C7.68342 127.098 8.31659 127.098 8.70711 126.707L15.0711 120.343C15.4616 119.953 15.4616 119.319 15.0711 118.929C14.6805 118.538 14.0474 118.538 13.6569 118.929L8.00001 124.586L2.34315 118.929C1.95263 118.538 1.31946 118.538 0.928937 118.929C0.538413 119.319 0.538413 119.953 0.928937 120.343L7.2929 126.707ZM7 1L7.00001 126L9.00001 126L9 1L7 1Z"
                                                        fill="black"
                                                    />
                                                </svg>
                                            </div>
                                            <div
                                                className="md:mx-10 my-4"
                                                style={dynamicStickerStyle}>
                                                <img
                                                    style={stickerContainerStyle}
                                                    src={selectedSticker || stickers[0].link}
                                                />
                                                <div
                                                    id="text-container-single"
                                                    style={dynamicTextContainerStyle}>
                                                    <div
                                                        className="first-row"
                                                        style={dynamicTextStyle}>
                                                        {firstRowText()}
                                                    </div>
                                                    <div
                                                        className="second-row"
                                                        style={dynamicTextStyle}>
                                                        {secondRowText()}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div
                                            className="text-center"
                                            style={{marginLeft: "25%"}}>
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="130"
                                                height="16"
                                                viewBox="0 0 302 16"
                                                fill="none">
                                                <path
                                                    d="M0.292893 7.29289C-0.0976311 7.68342 -0.0976311 8.31658 0.292893 8.70711L6.65685 15.0711C7.04738 15.4616 7.68054 15.4616 8.07107 15.0711C8.46159 14.6805 8.46159 14.0474 8.07107 13.6569L2.41421 8L8.07107 2.34315C8.46159 1.95262 8.46159 1.31946 8.07107 0.928932C7.68054 0.538408 7.04738 0.538408 6.65685 0.928932L0.292893 7.29289ZM301.707 8.70711C302.098 8.31658 302.098 7.68342 301.707 7.29289L295.343 0.928932C294.953 0.538408 294.319 0.538408 293.929 0.928932C293.538 1.31946 293.538 1.95262 293.929 2.34315L299.586 8L293.929 13.6569C293.538 14.0474 293.538 14.6805 293.929 15.0711C294.319 15.4616 294.953 15.4616 295.343 15.0711L301.707 8.70711ZM1 9H301V7H1V9Z"
                                                    fill="black"
                                                />
                                            </svg>
                                            <p className="md:text-xl">{width}mm</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            )}
            <hr className="w-1/2 border-t border-gray-400 my-10 mx-auto"/>
            {!process.env.NODE_ENV ||
                (process.env.NODE_ENV === "development" && <button onClick={downloadImage}>Download</button>)}
        </>
    );
}

export default App;
