import { useState } from "react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

export const FontPicker = ({ selectedFont, setSelectedFont, fonts, buttons }) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const toggleDropdown = () => setDropdownOpen(!dropdownOpen);

  const handleCloseDropdown = (event) => {
    if (!event.currentTarget.contains(event.relatedTarget)) {
      setDropdownOpen(false);
    }
  }

  const displayedFonts = fonts.slice(0, buttons);
  const dropdownFonts = fonts.slice(buttons);

  return (
    <div className="flex gap-4 items-start relative">
      {fonts.map((font, i) => (
        <link
          key={font.title}
          href={font.url}
          rel="stylesheet"
        />
      ))}
      {displayedFonts.map((font) => (
        <div key={font.title}>
          <button
            className={`w-16 h-16 border border-gray-300 hover:bg-gray-100 rounded ${
              selectedFont === font.title ? "ring-2 ring-offset-2 ring-gray-500" : ""
            }`}
            style={{ fontFamily: font.title }}
            onClick={() => setSelectedFont(font.title)}>
            TEXT
          </button>
        </div>
      ))}
      {dropdownFonts.length > 0 && (
        <div className="relative" onBlur={(event) => handleCloseDropdown(event)}>
          <button
            className="w-16 h-16 border border-gray-300 rounded bg-gray-100 hover:bg-gray-200 flex items-center justify-center"
            onClick={toggleDropdown}>
            <ExpandMoreIcon className="text-gray-700" />
          </button>
          {dropdownOpen && (
            <div className="absolute top-full right-0 w-64 mt-2 bg-white border border-gray-300 rounded shadow-lg z-10 grid grid-cols-3 gap-2 p-2">
              {dropdownFonts.map((font) => (
                <div key={font.title}>
                  <button
                    className={`w-16 h-16 border border-gray-300 rounded bg-center bg-cover ${
                      selectedFont === font.title ? "ring-2 ring-offset-2 ring-gray-500" : ""
                    }`}
                    style={{ fontFamily: font.title }}
                    onClick={() => {
                      setSelectedFont(font.title);
                    }}>
                    TEXT
                  </button>
                </div>
              ))}
            </div>
          )}
        </div>
      )}
    </div>
  );
};
