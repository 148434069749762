import { useState } from "react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const StickerPicker = ({ selectedSticker, setSelectedSticker, stickers, buttons }) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const toggleDropdown = () => setDropdownOpen(!dropdownOpen);

  const handleCloseDropdown = (event) => {
    if (!event.currentTarget.contains(event.relatedTarget)) {
      setDropdownOpen(false);
    }
  }

  const displayedStickers = stickers.slice(0, buttons);
  const dropdownStickers = stickers.slice(buttons);

  return (
    <div className="flex gap-4 items-start relative">
      {displayedStickers.map((sticker, index) => (
        <div key={sticker.link}>
          <button
            onClick={() => setSelectedSticker(sticker.link)}
            className={`w-16 h-16 border border-gray-300 rounded bg-center bg-cover ${
              selectedSticker === sticker.link ? "ring-2 ring-offset-2 ring-gray-500" : ""
            }`}
            style={{ backgroundImage: `url(${sticker.link})` }}
          />
        </div>
      ))}
      {dropdownStickers.length > 0 && (
        <div className="relative" onBlur={(event) => handleCloseDropdown(event)}>
          <button
            className="w-16 h-16 border border-gray-300 rounded bg-gray-100 hover:bg-gray-200 flex items-center justify-center"
            onClick={toggleDropdown}>
            <ExpandMoreIcon className="text-gray-700" />
          </button>
          {dropdownOpen && (
            <div className="absolute top-full right-0 w-64 mt-2 bg-white border border-gray-300 rounded shadow-lg z-10 grid grid-cols-3 gap-2 p-2">
              {dropdownStickers.map((sticker, index) => (
                <button
                  key={sticker.link}
                  onClick={() => setSelectedSticker(sticker.link)}
                  className={`w-16 h-16 border border-gray-300 rounded bg-center bg-cover ${
                      selectedSticker === sticker.link ? "ring-2 ring-offset-2 ring-gray-500" : ""
                  }`}
                  style={{ backgroundImage: `url(${sticker.link})` }}
                />
              ))}
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default StickerPicker;
