import React from "react";

export const TextInput = ({ id, label, value, onChange, onPaste }) => (
  <div className="mb-4">
    <div className="flex items-center gap-4">
      <label
        htmlFor={id}
        className="w-1/4 font-light">
        {label}
      </label>
      <input
        id={id}
        type="text"
        value={value}
        onChange={onChange}
        onPaste={onPaste}
        className="w-3/4 p-3 text-gray-700 border border-gray-300 rounded-md bg-gray-100 text-lg outline-none"
      />
    </div>
  </div>
);
